@font-face {
  font-family: CabinetGrotesk-Variable;
  src: url("CabinetGrotesk-Variable.4bdddac8.woff2") format("woff2"), url("CabinetGrotesk-Variable.892fc9d1.woff") format("woff"), url("CabinetGrotesk-Variable.6160fad9.ttf") format("truetype");
  font-weight: 100 900;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk;
  src: url("CabinetGrotesk-Thin.ef816f42.woff2") format("woff2"), url("CabinetGrotesk-Thin.c7a9dbb8.woff") format("woff"), url("CabinetGrotesk-Thin.fc68d53c.ttf") format("truetype");
  font-weight: 100;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk;
  src: url("CabinetGrotesk-Extralight.27227bcd.woff2") format("woff2"), url("CabinetGrotesk-Extralight.b521e26f.woff") format("woff"), url("CabinetGrotesk-Extralight.3891f20c.ttf") format("truetype");
  font-weight: 200;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk;
  src: url("CabinetGrotesk-Light.05bb344b.woff2") format("woff2"), url("CabinetGrotesk-Light.71aeadf5.woff") format("woff"), url("CabinetGrotesk-Light.388efb5b.ttf") format("truetype");
  font-weight: 300;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk;
  src: url("CabinetGrotesk-Regular.9d402eac.woff2") format("woff2"), url("CabinetGrotesk-Regular.581e521d.woff") format("woff"), url("CabinetGrotesk-Regular.51f4ac75.ttf") format("truetype");
  font-weight: 400;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk;
  src: url("CabinetGrotesk-Medium.d4be5229.woff2") format("woff2"), url("CabinetGrotesk-Medium.db6b90bb.woff") format("woff"), url("CabinetGrotesk-Medium.de3ef6e3.ttf") format("truetype");
  font-weight: 500;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk;
  src: url("CabinetGrotesk-Bold.db612632.woff2") format("woff2"), url("CabinetGrotesk-Bold.a365ca4f.woff") format("woff"), url("CabinetGrotesk-Bold.aef25caa.ttf") format("truetype");
  font-weight: 700;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk;
  src: url("CabinetGrotesk-Extrabold.c7de3028.woff2") format("woff2"), url("CabinetGrotesk-Extrabold.cc45ac4a.woff") format("woff"), url("CabinetGrotesk-Extrabold.b0e6c07b.ttf") format("truetype");
  font-weight: 800;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: CabinetGrotesk;
  src: url("CabinetGrotesk-Black.3ccec980.woff2") format("woff2"), url("CabinetGrotesk-Black.fc16288d.woff") format("woff"), url("CabinetGrotesk-Black.7403eb93.ttf") format("truetype");
  font-weight: 900;
  font-display: block;
  font-style: normal;
}

/*# sourceMappingURL=index.c4d06283.css.map */
